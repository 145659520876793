import React from 'react'
import ReactPlayer from 'react-player'
import styled from 'styled-components'
import './main.css'
import Logo from './logo.js'
import Loader from './loader'
{/*


const videoURL=
  'https://domina-louiza.de/louiza/wp-content/uploads/2019/05/001_chroma.mp4'


*/}
//const videoURL2 = 'http://domina-louiza.de/louiza/wp-content/uploads/2019/05/louiza_smoke.mp4'

const VideoContainer = styled.section`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 5rem;
  background-color: black;
  position: absolute;
  left: 0px;
  top: 0px;
  overflow: hidden;
`
const Title = styled.h1`
  color: #716a4d;
  z-index: 100;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  font-family: sans-serif;
  font-weight: 100;
  font-size: 1rem;
  cursor: pointer;
`

export default class Video extends React.Component {
  constructor(props) {
    super(props)
    this.Logo = React.createRef()
    this.Video = React.createRef()
    this.state = {
      loaded: true,
      playing: false,
      color: '#000',
    }
  }

  start = () => {
    console.log(this.Logo.current)
    this.setState({
      loaded: true,
      playing: true,
      logoClass: 'logoFadeIn',
      color: '#716A4D',
    })
  }
  onLoaded = () => {
    this.setState({loaded: true})
  }

  render() {
    return (
      <VideoContainer>
        {/*


        <ReactPlayer
          className={
            this.state.playing ? 'rp_container videoFadeIn' : 'rp_container'
          }
          url={videoURL}
          width="100%"
          height="auto"
          playing={this.state.playing}
          loop={true}
          onReady={this.onLoaded}
          muted
        />


        */}

        <Logo 
          className={
            this.state.playing ? 'logoFadeIn' : 'logo'
        } 
        color={this.state.color} />


        {!this.state.loaded ? (
          <Loader />
        ) : !this.state.playing ? (
          <Title onClick={this.start}>Ja, ich bin über 18</Title>
        ) : null}
      </VideoContainer>
    )
  }
}
