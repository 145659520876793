import React from 'react'
import './css/links.css'
import Template from './linksTemplate'

export default class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      pages: [],
    }
  }
  componentDidMount() {
    let dataURL = 'https://domina-louiza.de/louiza/wp-json/wp/v2/galerie'
    fetch(dataURL)
      .then(res => res.json())
      .then(res => {
        this.setState({pages: res})
      })
  }
  render() {
    const childElements = this.state.pages.map((element, i) => {
      if (element.slug.slice(0, 5) === 'links') {
        return (
          <Template
            key={i}
            title={element.title.rendered.substring(6).toUpperCase()}
            content={element.content.rendered}
          />
        )
      }
    })

    return (
      <div id="linkspage"
        style={{
          paddingTop: '200px'
        }} 
      
      >
        <div className="flag">
          <span className="title">Links</span>
        </div>
        <section id="links">{childElements}</section>
      </div>
    )
  }
}
