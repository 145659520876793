import React from 'react'
import 'react-video-cover'
import styled from 'styled-components'
import Template from './template'
import Gallery from './galerieGrid'
import Image from './image.js'
import Links from './links'
import SplashPage from './splash/'
import NEWS from './NEWS/'

/*
  const Headline = styled.h1`
  display: inline-block;
  font-size: 5.5rem;
  font-family: 'Helvetica Neue';
  font-weight: 200;
  color: var(--textcolor);
`
*/
const Panel = styled.div`
  width: 100vw;
  min-height: 100vh;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
`
/* user language *****************************************************/
const userLang =
  navigator.language.slice(0, 2) || navigator.userLanguage.slice(0, 2)
let lang
userLang === 'de' ? (lang = 'de') : (lang = 'en')
let termin,
  concept,
  contact,
  imprint,
  conceptLink,
  contactLink,
  imprintLink,
  cameraLink

if (lang === 'en') {
  conceptLink = 'Concept'
  cameraLink = 'Camera'
  contactLink = 'Contact'
  imprintLink = 'Imprint'
} else {
  conceptLink = 'Konzept'
  cameraLink = 'Kamera'
  contactLink = 'Kontakt'
  imprintLink = 'Impressum'
}

/* //user language *****************************************************/

export default class Splash extends React.Component {
  constructor(props) {
    super(props)
    this.first = React.createRef()
    this.second = React.createRef()
    this.state = {
      pages: [],
      en: null,
      de: null,
      contactVideo: null,
    }
  }

  componentWillMount() {
    let dataURL = 'https://domina-louiza.de/louiza/wp-json/app/lang/' + lang
    fetch(dataURL)
      .then(res => res.json())
      .then(res => {
        this.setState({pages: res})
      })

  }

  render() {
      this.state.pages.map(function(element, i) {
        if (element.category === '_termin') {
          termin = (
            <Template
              key={i}
              title={element.title}
              url={element.featured_image}
              height=")200px"
              bgSize="cover"
              bgColor="var(--textcolor)"
              bgText="rgba(0,0,0,0.5)"
              border="10px solid var(--textcolor)"
              content={element.content}
            />
          )
        }
        if (element.category === '_concept') {
          concept = (
            <Template
              key={i}
              title={element.title}
              url={element.featured_image}
              position="right"
              content={element.content}
            />
          )
        }
        {/* form aus Template herausgenommen */}
        if (element.category === '_contact') {
          contact = (
            <Template
              key={i}
              title={element.title}
              url={element.featured_image}
              position="left"
              content={element.content}
              video
            />
          )
        }
        if (element.category === '_imprint') {
          imprint = (
            <Template
              key={i}
              title={element.title}
              url={element.featured_image}
              bgSize="cover"
              position="top"
              content={element.content}
            />
          )
        }
      })

    return (
        <>
          <Panel>
            <SplashPage />
          </Panel>



          <Panel>
            <NEWS />
          </Panel>

          <Panel className="concept">{concept}</Panel>

          {/* Trennender Querstreifen mit fixed background
          */}

          <Image bg="/img/00004.jpg" />

      {/* termin && termin.length !== 0 ? termin : null */}
          <Panel
            className="camera"
            ref={section => {
              this.camera = section
            }}>
            <Gallery title={cameraLink} />
          </Panel>

          <Panel
            className="contact"
            ref={section => {
              this.contact = section
            }}
          >
            {' '}
            {contact}
          </Panel>

          <Links />

          <Panel
            className="imprint"
            ref={section => {
              this.imprint = section
            }}>
            {imprint}
          </Panel>
        </>
      )
  }
}
