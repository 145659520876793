import React from 'react'
import Lightbox from 'react-image-lightbox'
import styled from 'styled-components'

const Column = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  && > figure {
    width: 100%;
    display: inline-block;
    box-sizing: border-box;
    padding: 0.5rem;
  }
  && > figure img {
    width: 100%;
    height: auto;
    //filter: grayscale(100%);
    display: inline-block;
    box-sizing: border-box;
    transition: all 0.35s ease-out;
  }
  && > figure img:hover {
    mix-blend-mode: normal;
    -webkit-transform: scale(1.02);
    transform: scale(1.02);
  }
`

export default class GalleryColumn extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      images: [],
    }
  }

  componentDidMount() {
    //console.log(this.props.width)
    fetch(
      'https://domina-louiza.de/louiza/wp-json/app/fotos/' + this.props.gallery,
    )
      .then(res => res.json())
      .then(res => {
        //console.log(res)
        this.setState({images: res})
      })
  }
  diaShow = event => {
    this.setState({
      photoIndex: event.target.id,
      isOpen: true,
    })
  }

  render() {
    const images = this.state.images.map((element, i) => {
      return (
        <figure key={i}>
          <img src={element} id={i} alt={i} onClick={this.diaShow} />
        </figure>
      )
    })
    return (
      <>
        <Column>{images}</Column>

        {this.state.isOpen && (
          <Lightbox
            mainSrc={this.state.images[this.state.photoIndex]}
            nextSrc={
              this.state.images[
                (this.state.photoIndex + 1) % this.state.images.length
              ]
            }
            prevSrc={
              this.state.images[
                (this.state.photoIndex + this.state.images.length - 1) %
                  this.state.images.length
              ]
            }
            onCloseRequest={() => this.setState({isOpen: false})}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex:
                  (this.state.photoIndex + this.state.images.length - 1) %
                  this.state.images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex:
                  (this.state.photoIndex + 1) % this.state.images.length,
              })
            }
          />
        )}
      </>
    )
  }
}
