import React from 'react'
import GalleryColumn from './galleryColumn.js'

import Lightbox from 'react-image-lightbox'

import 'react-image-lightbox/style.css'
import styled from 'styled-components'
const Headline = styled.h1`
  width: 100%;
  display: inline-block;
  font-size: 2.5rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
  font-family: 'Helvetica Neue';
  font-weight: 200;
  color: var(--textcolor);
  @media (min-width: 1000px) {
    font-size: 5.5rem;
  }
`

const Page = styled.section`
  max-width: 100vw;
  display: flex;
  flex-wrap: wrap;
  padding: 0.5rem;
  background: linear-gradient(to bottom, #000000 0%, #ff6f61 100%);
  background: linear-gradient(to bottom, #ff6f61 0%, #000000 99%);
  background: linear-gradient(to bottom, #ff6f61 0%, #0000ff 52%, #000000 99%);
  background: linear-gradient(to bottom, #ffffff 0%, #000000 100%);
  background: blue;
  background: linear-gradient(to bottom, #000000 0%, #4713af 52%, #000000 99%);
  background: linear-gradient(
    to bottom,
    #000000 0%,
    #000000 5%,
    #000000 23%,
    #1b0d7a 52%,
    #000000 70%,
    #000000 99%
  );
  background: linear-gradient(
    to bottom,
    #000000 0%,
    #000000 5%,
    #000000 23%,
    #ff6d70 52%,
    #000000 70%,
    #000000 99%
  );
  background: linear-gradient(
    to bottom,
    #000000 0%,
    #000000 5%,
    #3100f7 26%,
    #ff6d70 71%,
    #000000 85%,
    #000000 99%
  );
  background: linear-gradient(
    to bottom,
    #000000 0%,
    #000000 5%,
    #000000 23%,
    #333333 52%,
    #000000 91%,
    #000000 99%
  );
`

class Gallery extends React.Component {
  constructor(props) {
    super(props)
    this.diaShow = this.diaShow.bind(this)
    this.state = {
      photoIndex: 0,
      isOpen: false,
    }
  }

  diaShow = event => {
    //console.log(event.target)

    /* 
     * this.setState({
      photoIndex: event.target.id,
      isOpen: true,
    })
    */
  }

  componentDidMount() {
    /* 
      allgemeine Galerie 190
      bsp. schuhe 267
      bsp. latex 270
      */
  }
  render() {
    return (
      <Page>
        <Headline>{this.props.title}</Headline>
        <section
          style={{
            width: '50%',
          }}>
          <GalleryColumn gallery={190} ellicit={this.diaShow} />
        </section>
        <section
          style={{
            width: '25%',
          }}>
          <GalleryColumn ellicit={this.diaShow} gallery={464} />
        </section>
        <section
          style={{
            width: '25%',
          }}>
          <GalleryColumn ellicit={this.diaShow} gallery={466} />
        </section>
        {/* 
          
          
          this.state.isOpen && (
          <Lightbox
            mainSrc={this.state.images[this.state.photoIndex]}
            nextSrc={
              this.state.images[
                (this.state.photoIndex + 1) % this.state.images.length
              ]
            }
            prevSrc={
              this.state.images[
                (this.state.photoIndex + this.state.images.length - 1) %
                  this.state.images.length
              ]
            }
            onCloseRequest={() => this.setState({isOpen: false})}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex:
                  (this.state.photoIndex + this.state.images.length - 1) %
                  this.state.images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex:
                  (this.state.photoIndex + 1) % this.state.images.length,
              })
            }
          />
        )

      */}
      </Page>
    )
  }
}

export default Gallery
