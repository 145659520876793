/*
 * section mit Hintergrundbild
 * als Trenner zwischen den anderen Seiten
 */

import React from 'react'
import styled from 'styled-components'

const Page = styled.section`
width: 100vw;
height: 23vh;
background-attachment: fixed;
background-size: cover;
background-position:center bottom;
padding: 0; margin: 0;
border: 2px solid: black; 

box-sizing: border-box;
opacity: 0.8;

overflow-x: hidden;
`

const Image = (props) => (
  <Page style={{ backgroundImage: 'url(' + props.bg + ')' }} />
)
export default Image
