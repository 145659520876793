/*********************************************************
 * Template für jede die Wordpress-Seiten: termin, konzept, impressum *
 * *******************************************************/

import React from 'react'
import styled from 'styled-components'
//import breakpoint from 'styled-components-breakpoint'
import ContactForm from './contactForm.js'

const Page = styled.div`
z-index: 0;
  position: relative;
  max-width: 100vw;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: ${props => (props.bgSize === 'cover' ? 'cover' : 'normal')};
  background-height: 100%;
  display: flex;
  color: var(--textcolor);
  padding-top: 5rem;
  @media (min-width: 800px) {
  }
  @media (min-width: 1000px) {
  }
  @media (min-width: 1400px) {
  }
`
const Content = styled.section`
  padding: 1em;
  text-align: justify;
  font-weight: 400;
  font-size: 18px;
  @media (min-width: 1000px) {
  }
  @media (min-width: 1200px) {
  }
  @media (min-width: 1400px) {
  }
  transition: all 250ms;
`
const Headline = styled.h1`
  display: inline-block;
  font-size: 2.5rem;
  margin-bottom: 1rem;
  font-family: 'Helvetica Neue';
  font-weight: 200;
  color: var(--textcolor);
  @media (min-width: 1000px) {
    font-size: 5.5rem;
  }
`

const Template = props => (
  <Page
    key={props.id}
    style={{
      minHeight: props.height || '100vh',
      border: props.border || 'none',
      backgroundImage: props.video ? null : 'url(' + props.url + ')',
      backgroundSize: props.bgSize || 'auto 100%',
      backgroundPosition: props.position,
      backgroundColor: props.bgColor || 'black',
    }}>
    <Content key={props.id + '1'}>
      <Headline
        style={{
          backgroundColor: props.bgText || 'none',
        }}>
        {props.title}
      </Headline>
      <section
        style={{
          backgroundColor: props.bgText || 'none',
        }}
        dangerouslySetInnerHTML={{__html: props.content}}
      />
      {props.form ? <ContactForm /> : null}
    </Content>
    {props.video && (
      <video
        id="background-video"
        src="https://domina-louiza.de/louiza/wp-content/uploads/2019/05/louiza_smoke.mp4"
        loop
        muted
        autoPlay
        style={{
          objectFit: 'cover',
          position: 'absolute',
          left: '0px',
          top: '0px',
          width: '100%',
          height: '100%',
          zIndex: '-1',
        }}
      />
    )}
  </Page>
)

export default Template
