import React from 'react'
import axios from 'axios'
import './contactForm.css'

const API_PATH = './api/contact/index.php'
const userLanguage = navigator.language.slice(0, 2)
let formItems
const formItemsDe = [
  'Nachricht',
  'Abschicken',
  'Vielen Dank für die Nachricht',
  'Da hat etwas nicht funktioniert. Die Nachricht wurde nicht an mich gesendet.',
]

const formItemsEn = [
  'Message',
  'Send',
  'Thank you for your message.',
  'Something went wrong. Your message has not been sent to me.',
]
userLanguage === 'de' ? (formItems = formItemsDe) : (formItems = formItemsEn)

export default class ContactForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      email: '',
      message: '',
      mailSent: false,
      error: null,
    }
  }
  handleFormSubmit = e => {
    e.preventDefault()
    this.setState({message: this.state.message.replace(/\r|\n|\r\n/g, '<br>')})
    axios({
      method: 'post',
      url: `${API_PATH}`,
      headers: {'content-type': 'application/json'},
      data: this.state,
    })
      .then(result => {
        this.setState({
          mailSent: result.data.sent,
        })
        console.log(this.state)
      })
      .catch(error => this.setState({error: error.message}))
  }
  registerText = e => {
    this.setState({message: e.target.value})
  }
  registerEmail = e => {
    this.setState({email: e.target.value})
  }
  registerName = e => {
    this.setState({name: e.target.value})
  }

  render() {
    const contactForm = this.state.mailSent ? (
      <div className="success">{formItems[2]}</div>
    ) : (
      <div className="form">
        <form action="#">
          <fieldset>
            <p className="field">
              <label>Name*</label>
              <input
                type="text"
                id="name"
                name="name"
                value={this.state.name}
                onChange={this.registerName}
                required
              />
            </p>

            <p className="field">
              <label>Email*</label>
              <input
                type="email"
                id="email"
                name="email"
                value={this.state.email}
                onChange={this.registerEmail}
                required
              />
            </p>
            <p className="field">
              <label>{formItems[0]}</label>
              <textarea
                style={{whiteSpace: 'preWrap'}}
                id="message"
                name="message"
                onChange={this.registerText}
                value={this.state.message}
              />
            </p>
            <div className="field">
              <button type="submit" onClick={e => this.handleFormSubmit(e)}>
                {formItems[1]}
              </button>
            </div>
            <div>
              {this.state.error && <div className="error">{formItems[3]}</div>}
            </div>
          </fieldset>
        </form>{' '}
      </div>
    )

    return (
      <>
        <>{contactForm}</>
      </>
    )
  }
}
