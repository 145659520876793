import React from 'react'

export default (props) => (
  <svg

    width="368.667"
    height="188.5"
    viewBox="0 0 377 173"
    style={{
      fillRule: 'evenodd',
      clipRule: 'evenodd',
      strokeLinejoin: 'round',
      strokeMiterlimit: '2',
      opacity: '0',
    }}
    fill="#716a4d"
    className={props.className}
  >
    <path
      style={{fill:'none'}}
      d="M-3.326-9.895h376.895v172.754H-3.326z"
      transform="translate(3.326 9.895)"
    />
    <path
      d="M57.586 91.334H5.146V5.654h2.4v83.28h50.04v2.4zm2.638-42.84c0-6.079.859-11.839 2.58-17.28 1.719-5.439 4.239-10.2 7.56-14.28 3.319-4.08 7.44-7.32 12.36-9.72 4.92-2.4 10.579-3.6 16.98-3.6 6.399 0 12.06 1.2 16.98 3.6 4.92 2.4 9.04 5.64 12.36 9.72 3.318 4.08 5.838 8.84 7.56 14.28 1.719 5.441 2.58 11.201 2.58 17.28 0 6.08-.86 11.84-2.58 17.279-1.722 5.44-4.242 10.199-7.56 14.279-3.321 4.08-7.44 7.303-12.36 9.66-4.92 2.357-10.581 3.54-16.98 3.54-6.401 0-12.06-1.183-16.98-3.54s-9.041-5.58-12.36-9.66c-3.321-4.08-5.841-8.839-7.56-14.279-1.721-5.439-2.58-11.199-2.58-17.279zm2.401 0c.079 6.16.979 11.84 2.7 17.04 1.719 5.2 4.159 9.681 7.32 13.438 3.16 3.762 7.02 6.701 11.58 8.82 4.56 2.121 9.72 3.18 15.48 3.18s10.92-1.059 15.48-3.18c4.56-2.119 8.419-5.059 11.58-8.82 3.16-3.759 5.599-8.238 7.32-13.438 1.719-5.2 2.619-10.88 2.7-17.04-.081-6.16-.98-11.839-2.7-17.04-1.722-5.2-4.161-9.679-7.32-13.44-3.161-3.76-7.02-6.7-11.58-8.82-4.56-2.119-9.72-3.18-15.48-3.18s-10.92 1.061-15.48 3.18c-4.56 2.121-8.42 5.061-11.58 8.82-3.161 3.761-5.601 8.24-7.32 13.44-1.722 5.201-2.622 10.88-2.7 17.04zm149.639-42.84v53.76c0 11.52-2.683 20.04-8.04 25.561-5.361 5.521-13.161 8.279-23.4 8.279-10.241 0-18.041-2.76-23.4-8.279-5.36-5.521-8.039-14.041-8.039-25.561V5.654h2.399v53.76c0 10.32 2.358 18.161 7.08 23.52 4.72 5.361 12.039 8.041 21.96 8.041 9.919 0 17.239-2.68 21.96-8.041 4.719-5.357 7.08-13.199 7.08-23.52V5.654h2.4zm13.077 12.12V5.654h2.4v12.12h-2.4zm0 73.56v-61.68h2.4v61.68h-2.4zm69.959 0h-63.119v-3.24l59.76-80.04h-56.52v-2.4h58.92v3.24l-59.881 80.04h60.84v2.4zm.362 0h-2.641l34.682-85.68h3.119l33.84 85.68h-2.641l-11.279-28.2h-43.681l-11.399 28.2zm33.718-83.28h-.239l-21.12 52.68h41.762L329.38 8.054z"
      style={{fill:'#716a4d',fillRule:'nonzero'}}
      transform="translate(5.723 16.423)"
    />
    <path
      d="M142 2.9c-35.175 7.367-65.016 23.367-90.032 48.382-25.905 25.905-42.16 56.506-49.524 93.46C.159 155.662.031 165.314.031 252.043c0 86.73.128 96.381 2.413 107.301 7.365 36.953 23.493 67.303 49.524 93.461 30.095 30.222 69.207 48.127 110.857 51.047 53.46 3.683 101.713-14.221 138.539-51.047 20.317-20.572 34.286-42.92 43.174-69.586 8.763-26.414 8.763-26.034 8.763-131.176 0-25.702 0-45.098-.128-60.121-.396-46.436-2.014-51.097-8.635-71.052-8.888-26.667-22.857-49.016-43.173-69.588-25.271-25.27-55.112-41.27-90.921-48.507C191.904-.909 160.159-.909 142 2.9zm70.984 26.287c58.03 15.492 98.92 58.285 112.126 117.46 2.001 8.82 2.633 13.083 2.832 47.712.078 13.73.088 32.232.088 57.684 0 89.65-.126 93.08-2.92 105.27-13.333 59.555-53.586 101.714-112.381 117.587-10.032 2.667-14.476 3.048-36.062 3.048-21.587 0-26.032-.381-35.938-3.048-58.92-15.873-99.173-58.032-112.506-117.587-2.795-12.19-2.922-15.62-2.922-104.636 0-102.347 0-102.093 9.27-128.126 17.905-49.904 61.841-87.237 114.54-97.269 14.349-2.794 50.539-1.651 63.873 1.905z"
      style={{fill:'#716a4d',fillRule:'nonzero'}}
      transform="matrix(.07586 0 0 .07586 171.486 126.172)"
    />
    <path
      d="M164.221 75.535c-.506.508-.888 30.731-.888 67.048v66.16l-26.54-27.429-26.54-27.302-8.761 8.635c-4.827 4.825-8.763 9.269-8.763 10.159 0 2.158 76.699 77.08 80.382 78.476 6.983 2.539 10.031.126 49.269-38.984 21.08-20.954 38.349-38.858 38.349-39.619 0-.763-3.809-5.08-8.381-9.652l-8.38-8.253-53.334 53.333-.634-66.413-.636-66.286-12.063-.381c-6.73-.253-12.572 0-13.08.508z"
      style={{fill:'#716a4d',fillRule:'nonzero'}}
      transform="matrix(.07586 0 0 .07586 171.486 126.172)"
    />
  </svg>
)
