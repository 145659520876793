/*********************************************************
 * Template für die  Links-Seite *
 * In WP braucht man ein Plugin mit dem man für die Bilder 'custom links' festlegen kann.
 * *******************************************************/

import React from 'react'
import styled from 'styled-components'
const Page = styled.span`
  width: 99%;
  color: var(--textcolor);
  margin: 0;
  @media (min-width: 1000px) {
    width: 50%;
  }
`
const Content = styled.section`
  padding-top: 2rem;
  padding-bottom: 1rem;
  text-align: justify;
  font-weight: 400;
  font-size: 18px;
`
const Headline = styled.h1`
  display: inline-block;
  font-size: 2.5rem;
  font-family: 'Helvetica Neue';
  font-weight: 200;
  max-width: 100%;
  @media (min-width: 1000px) {
    font-size: 4.5rem;
  }
`
const Template = props => (
  <Page key={props.id}>
    <Content key={props.id + '1'}>
      <Headline>{props.title}</Headline>
      <section
        className="gal"
        dangerouslySetInnerHTML={{__html: props.content}}
      />
    </Content>
  </Page>
)
export default Template
