import React, { Component } from 'react';
import Louiza from './components/'
//import './components/css/index.css';

class App extends Component {
  render() {
    return (
      <div className="App">
        <Louiza />
      </div>
    );
  }
}

export default App;
