import React from 'react'
import ReactPlayer from 'react-player'
import styled from 'styled-components'

const Container = styled.section`
  width: 100vw;
  height: 200vh;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;

  background: url('http://domina-louiza.de/img/camp-2.jpg');
  background-size: cover;
  backdrop-filter: blur(10px);
  overflow: hidden;
  box-sizing: border-box; 

  && a,
  img {
    margin: 0;
    padding: 0;
  }
`
const Title = styled.h1`
  color: #716a4d;
  z-index: 100;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  font-family: sans-serif;
  font-weight: 100;
  font-size: 1rem;
  cursor: pointer;
`
const IFRAME = styled.iframe`
  width: 100%;
  height: 200vh;
  overflow: hidden; 
  border: none;
  overflow-x: hidden;
  box-sizing:border-box;
`

export default class Video extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      /*
      <Container>
        <a href="https://www.jailhouse-events.com">
          <img src="http://domina-louiza.de/img/camp-2.jpg" alt="" />
        </a>
      </Container>
      */
      <IFRAME
        src="https://domina-louiza.de/NEWS/"
        title="AUF DER FARM / FTF II"
      />
    )
  }
}
